import React from "react";
import DropDown2 from "../../../Common/DropDown";

const NationalFilter = () => {
  return (
    <React.Fragment>
      <DropDown2
        fetchMoreData={() => {}}
        toggle={(e) => {
          e.stopPropagation();
        }}
        selectedItem={"Bangladesh"}
        disable
      >
        {[0, 1, 2]?.map((i, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            className={"grey dropDownItem"}
          >
            {/* div - #{index} */}
            <span
              style={{
                margin: "2px",
                padding: "4px",
                display: "block",
              }}
            >
              Bangladesh
            </span>
          </div>
        ))}
      </DropDown2>
    </React.Fragment>
  );
};

export default NationalFilter;
