import axios from 'axios';
import React, { useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import './detailsPage.scss';
import iconRetailer from './img/bag-2.svg';
import iconCluster from './img/map.svg';
import iconCount from './img/timer.svg';
const DetailsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    token,
    areaList,
    selectedRegion,
    selectedArea,
    pointList,
    selectedDistribution,
    selectedTerritory,
    selectedPoint,
    cluster,
    score,
    selectedTpg,
    outletScore,
    searchValue,
    outletData,
    outletLoading,
    isSearch,
    scoreOutletNum,
    multiSelectedArea,
    multiSelectedPoint,
    multiSelectedTerritory,
    multiSelectedDistribution,
    multiSelectedTpg,
    multiSelectedRegion
  } = useSelector((store) => ({
    token: store.Login.token,
    selectedPoint: store.GeoReducer.selectedPoint,
    selectedTerritory: store.GeoReducer.selectedTerritory,
    selectedDistribution: store.GeoReducer.selectedDistribution,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    cluster: store.GeoReducer.cluster,
    score: store.GeoReducer.score,
    outletScore: store.GeoReducer.outletScore,
    selectedTpg: store.GeoReducer.selectedTpg,
    outletData: store.GeoReducer.outletData,
    outletLoading: store.GeoReducer.outletLoading,
    isSearch: store.GeoReducer.isSearch,
    scoreOutletNum: store.GeoReducer.scoreOutletNum,
    multiSelectedPoint: store.GeoReducer.multiSelectedPoint,
    multiSelectedTerritory: store.GeoReducer.multiSelectedTerritory,
    multiSelectedDistribution: store.GeoReducer.multiSelectedDistribution,
    multiSelectedTpg: store.GeoReducer.multiSelectedTpg,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
  }));
  console.log('outletData', outletData);
  let clusterTo = Math.ceil(cluster?.[1]);
  let clusterFrom = Math.ceil(cluster?.[0]);
  let scoreTo = Math.ceil(score?.[1]);
  let scoreFrom = Math.ceil(score?.[0]);
  let outletScoreTo = Math.ceil(outletScore?.[1]);
  let outletScoreFrom = Math.ceil(outletScore?.[0]);
  const handledownLoad = () => {
    setLoading(true);
    const listPoint = multiSelectedPoint.map((item) => item.point);
    const listArea = multiSelectedArea.map((item) => item.area);
    const listTerritory = multiSelectedTerritory.map((item) => item.territory);
    const listDistribution = multiSelectedDistribution.map((item) => item.distributionHouse);
    const listTpg = multiSelectedTpg.map((item) => item.tpgName);
    const listRegion = multiSelectedRegion.map((item) => item.region);
    console.log('listRegion',listRegion);
    let body1= {}
    if(listArea.length>0){
      body1.areaNames= listArea

    }
    if(listRegion.length>0){
      body1.regionNames= listRegion
      
    }
    if(listDistribution.length>0){
      body1.distributeHouseNames= listDistribution
      
    }
    if(listTerritory.length>0){
      body1.territoryNames= listTerritory
      
    }
    if(listPoint.length>0){
      body1.pointNames= listPoint
      
    }
    if(listTpg.length>0){
      body1.tgpNames= listTpg
      
    }
    body1.fromScore = scoreFrom
    body1.toScore = scoreTo
    body1.clusterScoreFrom = clusterFrom
    body1.clusterScoreTo = clusterTo
    body1.outletScoreFrom = outletScoreFrom
    body1.outletScoreTo = outletScoreTo
    body1.scopeOutlet = scoreOutletNum || 100
   var body2 = JSON.stringify(body1)
console.log('body',JSON.stringify(body1));

    axios({
   
     url: `https://maac-analytics.salesx-staging.xyz/api/v1/outlet/download`,
      method: 'post',
      headers: { Authorization: `Bearer ${token}`,'Content-Type': 'application/json' },
      data: JSON.stringify(body1),
      responseType: 'blob',
    })
      .then((response) => {
        console.log('ff', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Result__.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  let ClusterconsumerFrequencyPrfm,
    ClusterValuePrfm,
    clusterVolumePrfm,
    priceCompliancePrfm;
  let merchandisingScorePerform,
    outletValuePerform,
    outletVolumePerform,
    outletTpgPerform;

  if (
    (outletData?.clusterScopeAverage?.consumerFrequency === "NaN") ||
    (outletData?.clusterPartnerAverage?.consumerFrequency ==="NaN")
  ) {
    ClusterconsumerFrequencyPrfm = 0.0;
  } else {
    ClusterconsumerFrequencyPrfm =
      ((parseFloat(
        outletData?.clusterScopeAverage?.consumerFrequency.toFixed(2)
      ) -
        parseFloat(
          outletData?.clusterPartnerAverage?.consumerFrequency.toFixed(2)
        )) /
        parseFloat(
          outletData?.clusterPartnerAverage?.consumerFrequency.toFixed(2)
        )) *
        100 || 0.0;
  }

  if (
    (outletData?.clusterScopeAverage?.value === "NaN") ||
    (outletData?.clusterPartnerAverage?.value ==="NaN")
  ) {
    ClusterValuePrfm = 0.0;
  } else {
    ClusterValuePrfm =
      ((parseFloat(outletData?.clusterScopeAverage?.value.toFixed(2)) -
        parseFloat(outletData?.clusterPartnerAverage?.value.toFixed(2))) /
        parseFloat(outletData?.clusterPartnerAverage?.value.toFixed(2))) *
      100;
  }

  if (
    (outletData?.clusterScopeAverage?.volume=== "NaN") ||
    (outletData?.clusterPartnerAverage?.volume === "NaN")
  ) {
    clusterVolumePrfm = 0.0;
  } else {
    clusterVolumePrfm =
      ((parseFloat(outletData?.clusterScopeAverage?.volume.toFixed(2)) -
        parseFloat(outletData?.clusterPartnerAverage?.volume.toFixed(2))) /
        parseFloat(outletData?.clusterPartnerAverage?.volume.toFixed(2))) *
      100;
  }

  if (
    (outletData?.clusterScopeAverage?.priceCompliance ==="NaN") ||
    (outletData?.clusterPartnerAverage?.priceCompliance ==="NaN")
  ) {
    priceCompliancePrfm = 0.0;
  } else {
    priceCompliancePrfm =
      ((parseFloat(
        outletData?.clusterScopeAverage?.priceCompliance.toFixed(2)
      ) -
        parseFloat(
          outletData?.clusterPartnerAverage?.priceCompliance.toFixed(2)
        )) /
        parseFloat(
          outletData?.clusterPartnerAverage?.priceCompliance.toFixed(2)
        )) *
      100;
  }

  if (
    (outletData?.outletScopeAverage?.merchandisingScore ==="NaN") ||
    (outletData?.outletPartnerAverage?.merchandisingScore ==="NaN")
  ) {
    merchandisingScorePerform = 0.0;
  } else {
    merchandisingScorePerform =
      ((parseFloat(
        outletData?.outletScopeAverage?.merchandisingScore.toFixed(2)
      ) -
        parseFloat(
          outletData?.outletPartnerAverage?.merchandisingScore.toFixed(2)
        )) /
        parseFloat(
          outletData?.outletPartnerAverage?.merchandisingScore.toFixed(2)
        )) *
        100 || 0.0;
  }

  if (
    (outletData?.outletScopeAverage?.valueScore==="NaN") ||
    (outletData?.outletPartnerAverage?.valueScore ==="NaN")
  ) {
    outletValuePerform = 0.0;
  } else {
    outletValuePerform =
      ((parseFloat(outletData?.outletScopeAverage?.valueScore.toFixed(2)) -
        parseFloat(outletData?.outletPartnerAverage?.valueScore.toFixed(2))) /
        parseFloat(outletData?.outletPartnerAverage?.valueScore.toFixed(2))) *
      100;
  }

  if (
   (outletData?.outletScopeAverage?.volumeScore ==="NaN") ||
    (outletData?.outletPartnerAverage?.volumeScore ==="NaN")
  ) {
    outletVolumePerform = 0.0;
  } else {
    outletVolumePerform =
      ((parseFloat(outletData?.outletScopeAverage?.volumeScore.toFixed(2)) -
        parseFloat(outletData?.outletPartnerAverage?.volumeScore.toFixed(2))) /
        parseFloat(outletData?.outletPartnerAverage?.volumeScore.toFixed(2))) *
        100 || 0.0;
  }


  if (
    (outletData?.outletScopeAverage?.tpgScore==="NaN") ||
    (outletData?.outletPartnerAverage?.tpgScore==="NaN")
  ) {
    outletTpgPerform = 0.0;
  } else {
    outletTpgPerform =
      ((parseFloat(outletData?.outletScopeAverage?.tpgScore.toFixed(2)) -
        parseFloat(outletData?.outletPartnerAverage?.tpgScore.toFixed(2))) /
        parseFloat(outletData?.outletPartnerAverage?.tpgScore.toFixed(2))) *
      100;
  }


  let grandPerformance =
    ClusterconsumerFrequencyPrfm +
    ClusterValuePrfm +
    clusterVolumePrfm +
    priceCompliancePrfm +
    merchandisingScorePerform +
    outletValuePerform +
    outletVolumePerform +
    outletTpgPerform;

  let grandScopeAverage =
    outletData?.clusterScopeAverage?.consumerFrequency +
    outletData?.clusterScopeAverage?.value +
    outletData?.clusterScopeAverage?.volume +
    outletData?.clusterScopeAverage?.priceCompliance +
    outletData?.outletScopeAverage?.merchandisingScore +
    outletData?.outletScopeAverage?.valueScore +
    outletData?.outletScopeAverage?.volumeScore +
    outletData?.outletScopeAverage?.tpgScore;

  let grandCurrentPartner =
    outletData?.clusterPartnerAverage?.consumerFrequency +
    outletData?.clusterPartnerAverage?.value +
    outletData?.clusterPartnerAverage?.volume +
    outletData?.clusterPartnerAverage?.priceCompliance +
    outletData?.outletPartnerAverage?.merchandisingScore +
    outletData?.outletPartnerAverage?.valueScore +
    outletData?.outletPartnerAverage?.volumeScore +
    outletData?.outletPartnerAverage?.tpgScore;

  return (
    <div>
      {outletLoading && Object.keys(outletData).length === 0 ? (
        <div className="text-center pt-5">
          <Spinner />
        </div>
      ) : (
        <>
          <Container>
            {outletLoading && Object.keys(outletData).length ? (
              <div className="mt-5 text-center">
                <Spinner />
              </div>
            ) : null}

            <div className="table-data-content-full-details-main-wrap">
              <div className="search-over-view-main-wrap">
                <div className="search-over-view-title">
                  <h4>Search overview</h4>
                </div>
                <Row>
                  <Col>
                    <div className="search-single-box-item">
                      <img src={iconRetailer} alt="" />
                      <h6>{outletData?.scopeRetailer}</h6>
                      <span>Scope Retailer</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="search-single-box-item">
                      <img src={iconCluster} alt="" />
                      <h6>{outletData?.scopeCluster}</h6>
                      <span>Scope Cluster </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="search-single-box-item">
                      <img src={iconCount} alt="" />
                      <h6>{outletData?.scopeCount}</h6>
                      <span>Scope Count</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="table-data-content-full-details">
                <Table bordered className="rounded">
                  <thead>
                    <tr>
                      <th>Broad Head</th>
                      <th>Points Assigned</th>
                      <th>Criteria</th>
                      <th>Scope's Average</th>
                      <th>Current Partner Average</th>
                      <th>Performance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="4">Cluster</td>
                      <td rowspan="4">30</td>
                      <td>Consumer Frequency</td>
                      {isNaN(
                        outletData?.clusterScopeAverage?.consumerFrequency
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterScopeAverage?.consumerFrequency?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {isNaN(
                        outletData?.clusterPartnerAverage?.consumerFrequency
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterPartnerAverage?.consumerFrequency?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(ClusterconsumerFrequencyPrfm) ? <td>0.00%</td> :
                        ClusterconsumerFrequencyPrfm < 0 ? (
                          <td className="text-danger fw-bold">
                            {ClusterconsumerFrequencyPrfm.toFixed(2)} %
                          </td>
                        ) : ClusterconsumerFrequencyPrfm > 0 ? (
                          <td className="fw-bold" style={{ color: '#67d611' }}>
                            {ClusterconsumerFrequencyPrfm.toFixed(2)} %
                          </td>
                        ) : (
                          <td>{ClusterconsumerFrequencyPrfm.toFixed(2)} %</td>
                        )
                      }
                    
                    </tr>
                    <tr>
                      <td>Value</td>
                      {isNaN(outletData?.clusterScopeAverage?.value) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterScopeAverage?.value?.toFixed(2)}
                        </td>
                      )}
                      {isNaN(outletData?.clusterPartnerAverage?.value) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterPartnerAverage?.value?.toFixed(2)}
                        </td>
                      )}
                      {
                        isNaN(ClusterValuePrfm) ? <td>0.00%</td> :
                        ClusterValuePrfm < 0 ? (
                          <td className="text-danger fw-bold">
                            {ClusterValuePrfm.toFixed(2)} %
                          </td>
                        ) : ClusterValuePrfm > 0 ? (
                          <td>
                            <span
                              className=" fw-bold"
                              style={{ color: '#67d611' }}
                            >
                              {ClusterValuePrfm.toFixed(2)} %
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span>{ClusterValuePrfm.toFixed(2)} %</span>
                          </td>
                        )
                      }
                     
                    </tr>{' '}
                    <tr>
                      <td>Volume</td>
                      {isNaN(outletData?.clusterScopeAverage?.volume) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterScopeAverage?.volume?.toFixed(2)}
                        </td>
                      )}
                      {isNaN(outletData?.clusterPartnerAverage?.volume) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterPartnerAverage?.volume?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(clusterVolumePrfm) ? <td>0.00 %</td> :
                        clusterVolumePrfm < 0 ? (
                          <td className="text-danger fw-bold">
                            {clusterVolumePrfm.toFixed(2)}%
                          </td>
                        ) : clusterVolumePrfm > 0 ? (
                          <td className=" fw-bold" style={{ color: '#67d611' }}>
                            {clusterVolumePrfm.toFixed(2)}%
                          </td>
                        ) : (
                          <td>{clusterVolumePrfm.toFixed(2)}%</td>
                        )
                      }
                     
                    </tr>{' '}
                    <tr>
                      <td>Price Compliance Importance</td>
                      {isNaN(
                        outletData?.clusterScopeAverage?.priceCompliance
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterScopeAverage?.priceCompliance?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {isNaN(
                        outletData?.clusterPartnerAverage?.priceCompliance
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.clusterPartnerAverage?.priceCompliance?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(priceCompliancePrfm) ? <td>0.00 %</td>
                        : priceCompliancePrfm < 0 ? (
                          <td className="text-danger fw-bold">
                            {priceCompliancePrfm.toFixed(2)} %
                          </td>
                        ) : priceCompliancePrfm > 0 ? (
                          <td className="fw-bold" style={{ color: '#67d611' }}>
                            {priceCompliancePrfm.toFixed(2)} %
                          </td>
                        ) : (
                          <td>{priceCompliancePrfm.toFixed(2)} %</td>
                        )
                      }
                     
                    </tr>
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      <td rowspan="4">Outlet</td>
                      <td rowspan="4">70</td>
                      <td>Merchandising Score</td>
                      {isNaN(
                        outletData?.outletScopeAverage?.merchandisingScore
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletScopeAverage?.merchandisingScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {isNaN(
                        outletData?.outletPartnerAverage?.merchandisingScore
                      ) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletPartnerAverage?.merchandisingScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(merchandisingScorePerform) ? <td>0.00 %</td> :
                        merchandisingScorePerform < 0 ? (
                          <td className="text-danger fw-bold">
                            {merchandisingScorePerform.toFixed(2)} %
                          </td>
                        ) : merchandisingScorePerform > 0 ? (
                          <td className="fw-bold" style={{ color: '#67d611' }}>
                            {merchandisingScorePerform.toFixed(2)} %
                          </td>
                        ) : (
                          <td>{merchandisingScorePerform.toFixed(2)} %</td>
                        )
                      }
                     
                    </tr>
                    <tr>
                      <td>Value</td>
                      {isNaN(outletData?.outletScopeAverage?.valueScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletScopeAverage?.valueScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {isNaN(outletData?.outletPartnerAverage?.valueScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletPartnerAverage?.valueScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(outletValuePerform) ? <td>0.00 %</td> :
                        outletValuePerform < 0 ? (
                          <td>
                            <span className="text-danger fw-bold">
                              {outletValuePerform.toFixed(2)}%
                            </span>
                          </td>
                        ) : outletValuePerform > 0 ? (
                          <td>
                            <span
                              className="fw-bold"
                              style={{ color: '#67d611' }}
                            >
                              {outletValuePerform.toFixed(2)}%
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span>{outletValuePerform.toFixed(2)}%</span>
                          </td>
                        )
                      }
                    
                    </tr>{' '}
                    <tr>
                      <td>Volume</td>
                      {isNaN(outletData?.outletScopeAverage?.volumeScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletScopeAverage?.volumeScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {isNaN(outletData?.outletPartnerAverage?.volumeScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletPartnerAverage?.volumeScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(outletVolumePerform) ? <td>0.00%</td> :
                        outletVolumePerform < 0 ? (
                          <td className="text-danger fw-bold">
                            <span>{outletVolumePerform.toFixed(2)} %</span>
                          </td>
                        ) : outletVolumePerform > 0 ? (
                          <td>
                            <span
                              className="fw-bold"
                              style={{ color: '#67d611' }}
                            >
                              {outletVolumePerform.toFixed(2)} %
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span>{outletVolumePerform.toFixed(2)} %</span>
                          </td>
                        )
                      }
                  
                    </tr>{' '}
                    <tr>
                      <td>Tpg Score</td>
                      {isNaN(outletData?.outletScopeAverage?.tpgScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletScopeAverage?.tpgScore?.toFixed(2)}
                        </td>
                      )}
                      {isNaN(outletData?.outletPartnerAverage?.tpgScore) ? (
                        <td>0.00</td>
                      ) : (
                        <td>
                          {outletData?.outletPartnerAverage?.tpgScore?.toFixed(
                            2
                          )}
                        </td>
                      )}
                      {
                        isNaN(outletTpgPerform) ?  <td>0.00 %</td> :
                        outletTpgPerform < 0 ? (
                          <td className="text-danger fw-bold">
                            {outletTpgPerform.toFixed(2)} %
                          </td>
                        ) : outletTpgPerform > 0 ? (
                          <td className=" fw-bold" style={{ color: '#67d611' }}>
                            {outletTpgPerform.toFixed(2)} %
                          </td>
                        ) : (
                          <td>{outletTpgPerform.toFixed(2)} %</td>
                        )
                      }
                   
                    </tr>
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <span className="text-end d-block">Grand Total -</span>
                      </td>
                      {isNaN(grandScopeAverage) ? (
                        <td>0.00 </td>
                      ) : (
                        <td>{grandScopeAverage.toFixed(2)}</td>
                      )}
                      {isNaN(grandCurrentPartner) ? (
                        <td>0.00 </td>
                      ) : (
                        <td>{grandCurrentPartner.toFixed(2)}</td>
                      )}

                      {isNaN(grandPerformance) ? (
                        <td>0.00 %</td>
                      ) : grandPerformance > 0 ? (
                        <td style={{ color: '#67d611' }} className="fw-bold">
                          {grandPerformance.toFixed(2)}%
                        </td>
                      ) : (
                        <td className="text-danger fw-bold">
                          {grandPerformance.toFixed(2)}%
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
                <div className="search-main-button-wrap ">
                  <button
                    className="mb-3"
                    onClick={handledownLoad}
                    disabled={loading}
                  >
                    {loading ? 'Downloading...' : 'Download Excel'}
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};
export default DetailsPage;
