import { get, patch, post } from './api_helper';

export const getData = (url, token) =>
  get(url, { headers: { Authorization: `Bearer ${token}` } });

export const postData = (url, data, token) =>
  post(url, data, { headers: { Authorization: `Bearer ${token}` } });

export const patchData = (url, data, token) =>
  patch(url, data, { headers: { Authorization: `Bearer ${token}` } });
