import React from "react";
import "./footer.scss";
const Footer = () => {
  return (
    <div>
      <div className="footer-main-area-wrapp">
        <p>All right reserved by MAAC</p>
      </div>
    </div>
  );
};

export default Footer;
