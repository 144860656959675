import React, { useState } from "react";
import leftShape from "./img/Login-shape.svg";
import "./login.scss";
import { Form, Button } from "react-bootstrap";
import { loginUser } from "../../store/actions"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import loGoMain from "../../Common/img/logo.svg";


const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")

  const handleSubmit = (e) =>{
  e.preventDefault()
    const LoginValue = {email:email, password: password} 
    dispatch(loginUser(LoginValue, history))
  }
  return (
    <div className="login-main-area-wrap">
      <div className="login-inner-main-wrap">
        <div className="login-area-left-part">
          <div className="bg-shape-image-left">
            <img src={leftShape} alt="" />
          </div>
          <span>
            <div>
            <img src={loGoMain} alt=""  className="home-logo" />
            </div>
         
           
          </span>
        </div>
        <div className="login-area-right-part">
          <span>
            <h2>Hello Again!</h2>
            <p>Welcome Back</p>
            <div className="login-main-form-area-right">
              <Form onSubmit={handleSubmit} >
                <div className="single-login-input-field">
                  <Form.Group controlId="formBasicEmail">
                    <span>
                      <i className="bx bx-envelope"></i>
                    </span>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                  </Form.Group>
                </div>
                <div className="single-login-input-field">
                  <Form.Group controlId="formBasicPassword">
                    <span>
                      <i className="bx bx-lock"></i>
                    </span>

                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value) }/>
                  </Form.Group>
                </div>
                {/* <div className="single-login-input-field input-res-err-success-m mb-4">
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </div> */}
                <div className="single-login-input-field">
                  <Button className=" login-main-button-wrap" type="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
