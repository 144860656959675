import React from "react";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../store/Login/action";
import loGoMain from "../img/logo.svg";
import "./header.scss";
const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((store) => ({
    user: store.Login,
  }));
 
  const handleLogout = () => {
 
    dispatch(logoutUser(history));
    history.push("/");
  };

  return (
    <div>
      <div className="bat-main-navbar-wrap">
        <Navbar expand="lg" className="navbar-main-area-wrap">
          <Container>
            <Navbar.Brand href="#">
              <img src={loGoMain} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Dropdown>
                  <Dropdown.Toggle
                    className="header-profile-button-area"
                    id="dropdown-basic"
                  >
                    {/* <img src={profileImage} alt="" /> */}
                    <span>{user.name}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleLogout()}>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
