import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, loginUserError } from "./action"
import { postLogin } from "../../helpers/api_helper"
const loginWithEmployeeIdAsync = async (email, password) => {
    try {
      const response = await postLogin(email, password)
      console.log("hello response", response.data)
      return response.data
    } catch (error) {
      console.log("response from backend error", error.response.data)
       return error.response.data
    }
  }
  
  function* loginUser({ payload: { user, history } }) {
    const { email, password } = user
    try {
      console.log("from 22 line", email, password)
  
      const loginUserResponse = yield call(
        loginWithEmployeeIdAsync,
        email,
        password
      )
  
      console.log("response data from line 42", loginUserResponse)
      if (loginUserResponse.statusCode === 200) {
        console.log('hello');
        yield put(
          loginSuccess(
            loginUserResponse.name,
            loginUserResponse.token,   
            loginUserResponse.email,
          
          )
        )
        console.log(loginUserResponse);
      
           history.push("/dashboard")

      }
       
      else {
        console.log("data error", loginUserResponse.message)
       
        yield put(loginUserError())
      }
    } catch (err) {
      console.log("error from here", err)
    }
  }
  
  function* logoutUser({ history }) {
    console.log('history',history);
    try {
      console.log('hello log');
     

    } catch (error) {
      console.log("error data", error)
    }
  }
  
function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
  }
  export default authSaga
  