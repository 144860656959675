import React, { useState } from "react";
import { getTrackBackground, Range } from "react-range";
import { useDispatch, useSelector } from "react-redux";
import { storeGeoData } from "../../store/Geo/action";

const STEP = 1;
const MIN = 0;
const MAX = 30;
const COLORS = ["#D8E7FF", "#D8E7FF", "#D8E7FF"];

const Cluster = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState([0, 30]);

  const { token, cluster } = useSelector((store) => ({
    token: store.Login.token,
    cluster: store.GeoReducer.cluster,
  }));

  return (
    <div className="">
      <>
        <div className="geo-page-inner-wrap range-main-hjyf jfhflh">
          <div className="geo-page-title">
            <h3>Cluster</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Range
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => {
                setValues(values);
                dispatch(storeGeoData("cluster", values));
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                  className="range-main-bar-width"
                >
                  <div
                    className="range-main-bar-inner-hdsjb cluster-inner-hnddb"
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values,
                        colors: COLORS,
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "42px",
                    width: "42px",
                    borderRadius: "4px",
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                  className="range-main-bar-inner-ygdhgg"
                >
                  {/* <div
                    style={{
                      height: "16px",
                      width: "5px",
                      backgroundColor: isDragged ? COLORS[index] : "#CCC",
                    }}
                    className="range-main-bar-inner-hcefhn"
                  /> */}
                </div>
              )}
            />
            <div className="range-output-data-content-view">
              <div className="data-content-view-lisdjh">
                <h4>{Math.ceil(values[0])}</h4>
              </div>
              <div className="data-content-view-lisdjh">
                <h4>{Math.ceil(values[1])}</h4>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Cluster;
