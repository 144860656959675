import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, postData } from '../../helpers/backend_helper';
import { toaster } from '../../helpers/custom/toaster';
import { getOutletDataSuccess, storeGeoData, storeNewData } from './action';
import {
  GET_AREA,
  GET_DISTRIBUTION,
  GET_OUTLET,
  GET_POINT,
  GET_REGION,
  GET_TERRITORY,
  GET_TPG,
} from './actionTypes';

function* onLoadRegion({ payload: { token, searchValue } }) {
  try {
    const url = `/region?key=${searchValue}`;
    const response = yield call(getData, url, token);
    yield put(storeGeoData('regionList', response?.regions));
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Region Failed';
    toaster('error', message);
  }
}

function* onLoadArea({
  payload: { token, searchValue, regionName, page, setPage, setHasMore, isNew },
}) {
  try {
    const url = `/area?key=${searchValue}${
      regionName ? `&regionNames= ${regionName}` : ''
    }&page=${page}&limit=10`;
    const response = yield call(getData, url, token);
    console.log('response', response);
    if (response?.areas?.length < 10) {
      setHasMore && setHasMore(false);
    } else {
      setPage && setPage(page + 1);
    }
    if (isNew) {
      yield put(storeGeoData('areaList', response?.areas));
    } else {
      yield put(storeNewData('areaList', response?.areas));
    }
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Area Failed';
    toaster('error', message);
  }
}
function* onLoadDistributionHouse({
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
}) {
  try {
    const url = `/distribution-house?key=${searchValue}${
      regionName ? `&regionNames= ${regionName}` : ''
    }${areaName ? `&areaNames= ${areaName}` : ''}&page=${page}&limit=10`;
    const response = yield call(getData, url, token);
    if (response?.distributionHouses?.length < 10) {
      setHasMore && setHasMore(false);
    } else {
      setPage && setPage(page + 1);
    }
    if (isNew) {
      yield put(storeGeoData('distributionList', response?.distributionHouses));
    } else {
      yield put(storeNewData('distributionList', response?.distributionHouses));
    }
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Distribution Failed';
    toaster('error', message);
  }
}
function* onLoadTerritory({
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    distributionHouseName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
}) {
  try {
    const url = `/territory`;
   let body= {  
      key: searchValue ,
      limit: 10,
      page: page,
    }
    if(areaName.length>0){
      body.areaNames= areaName

    }
    if(regionName.length>0){
      body.regionNames= regionName
      
    }
    if(distributionHouseName.length>0){
      body.distributionHouseNames= distributionHouseName
      
    }
    const response = yield call(postData, url, body, token);
    if (response?.territories?.length < 10) {
      setHasMore && setHasMore(response?.territories?.length < 10);
    } else {
      setPage && setPage(page + 1);
    }
    yield put(storeNewData('territoryList', response?.territories));

    // if (isNew) {
    //   yield put(storeGeoData('territoryList', response?.territories));
    // } else {
    // }
    yield put(storeGeoData('areaListLoading', false));
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Territory Failed';
    yield put(storeGeoData('areaListLoading', false));
    toaster('error', message);
  }
}
function* onLoadPoint({
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    distributionHouseName,
    territoryName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
}) {
  try {
    const url = `/point`;
    let body= {  
      key: searchValue ,
      limit: 10,
      page: page,
    }
    if(areaName.length>0){
      body.areaNames= areaName

    }
    if(regionName.length>0){
      body.regionNames= regionName
      
    }
    if(distributionHouseName.length>0){
      body.distributionHouseNames= distributionHouseName
      
    }
    if(territoryName.length>0){
      body.territoryNames= territoryName
      
    }
    const response = yield call(postData, url, body, token);
    if (response?.points?.length < 10) {
      setHasMore && setHasMore(false);
    } else {
      setPage && setPage(page + 1);
    }
    if (isNew) {
      yield put(storeGeoData('pointList', response?.points));
    } else {
      yield put(storeNewData('pointList', response?.points));
    }
    // yield put(storeGeoData('pointList', response?.points));
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Point Failed';
    toaster('error', message);
  }
}

function* onLoadTpg({ payload: { token, searchValue } }) {
  try {
    const url = `/tpg?key=${searchValue}`;
    const response = yield call(getData, url, token);
    yield put(storeGeoData('tpgList', response?.tpgs));
  } catch (error) {
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Region Failed';
    toaster('error', message);
  }
}
function* onLoadOutletResult({
  payload: {
    token,
    regionName,
    areaName,
    distributeHouseName,
    territoryName,
    pointName,
    tpgName,
    fromScore,
    toScore,
    clusterScoreFrom,
    clusterScoreTo,
    outletScoreFrom,
    outletScoreTo,
    scopeOutlet,
  },
}) {
  try {
    const url = `/outlet/search`
    let body= {}
    if(areaName.length>0){
      body.areaNames= areaName

    }
    if(regionName.length>0){
      body.regionNames= regionName
      
    }
    if(distributeHouseName.length>0){
      body.distributeHouseNames= distributeHouseName
      
    }
    if(territoryName.length>0){
      body.territoryNames= territoryName
      
    }
    if(pointName.length>0){
      body.pointNames= pointName
      
    }
    if(tpgName.length>0){
      body.tgpNames= tpgName
      
    }
    body.fromScore = fromScore
    body.toScore = toScore
    body.clusterScoreFrom = clusterScoreFrom
    body.clusterScoreTo = clusterScoreTo
    body.outletScoreFrom = outletScoreFrom
    body.outletScoreTo = outletScoreTo
    body.scopeOutlet = scopeOutlet
    console.log("ss",body);
   
    const response = yield call(postData, url, body, token);
    yield put(getOutletDataSuccess(response));
  } catch (error) {
    yield put(getOutletDataSuccess({}));
    console.log(`error`, error.message);
    const message = error.response.data.message || 'Load Outlet Failed';
    toaster('error', message);
  }
}

function* GeoSaga() {
  yield takeEvery(GET_REGION, onLoadRegion);
  yield takeEvery(GET_AREA, onLoadArea);
  yield takeEvery(GET_DISTRIBUTION, onLoadDistributionHouse);
  yield takeEvery(GET_TERRITORY, onLoadTerritory);
  yield takeEvery(GET_POINT, onLoadPoint);
  yield takeEvery(GET_TPG, onLoadTpg);
  yield takeEvery(GET_OUTLET, onLoadOutletResult);
}
export default GeoSaga;
