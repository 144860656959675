import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../../Common/MultiSelect';
import { toaster } from '../../../helpers/custom/toaster';
import useInfiniteScroll from '../../../Hooks/useInfiniteScroll';
import { storeGeoData } from '../../../store/actions';
const RegionFilter = () => {
  const dispatch = useDispatch();
  const [reRender] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [sizesOpen, setSizesOpen] = useState(false);

  const { token, multiSelectedRegion } = useSelector((store) => ({
    token: store.Login.token,
    regionList: store.GeoReducer.regionList,
    selectedRegion: store.GeoReducer.selectedRegion,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
  }));

  // const { value, onChange, setValue } = useDebounce(
  //   () => dispatch(getRegionData(token, value)),
  //   1000
  // );

  // const handleSelectData = (data) => {
  //   dispatch(storeGeoData('selectedRegion', data));
  //   dispatch(storeGeoData('selectedArea', {}));
  //   dispatch(storeGeoData('selectedDistribution', {}));
  //   dispatch(storeGeoData('selectedTerritory', {}));
  //   setSizesOpen(!sizesOpen);
  // };

  // useEffect(() => {
  //   dispatch(getRegionData(token, value));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleSelectMultiData = (data) => {
    dispatch(
      storeGeoData('multiSelectedRegion', [...multiSelectedRegion, data])
    );
    setSizesOpen(!sizesOpen);
  };

  const handleRemove = (i, data) =>
    dispatch(
      storeGeoData(
        'multiSelectedRegion',
        multiSelectedRegion?.filter(
          (item, idx) => item.region !== data.region && idx !== i
        )
      )
    );

  const { loading, error, data, handleSearch } = useInfiniteScroll({
    method: 'Get',
    url: `/region?key=${query}`,
    body: {},
    token,
    name: 'regions',
    responseCallback: (data) => {
      // console.log('CallBack', data);
    },
    query,
    page,
    setQuery,
    setPage,
    reRender,
  });

  return (
    <React.Fragment>
      {/* <DropDown2
        fetchMoreData={() => {}}
        toggle={(e) => {
          e.stopPropagation();
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={regionList}
        isSelected={Object.keys(selectedRegion)?.length > 0}
        handleClear={() => {
          dispatch(storeGeoData('selectedRegion', {}));
          dispatch(storeGeoData('selectedArea', {}));
          dispatch(storeGeoData('selectedDistribution', {}));
          dispatch(storeGeoData('selectedTerritory', {}));
        }}
        selectedItem={selectedRegion?.region || 'Select Region'}
        hasMore={false}
      >
        {regionList?.map((i, index) => (
          <div
            key={index}
            onClick={() => handleSelectData(i)}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
        
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.region}
            </span>
          </div>
        ))}
      </DropDown2> */}
      {/* <MultiSelectDropDown
        fetchMoreData={() => {}}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={regionList}
        // hasMore={hasMore}
        // scrollDiv={'tpgFilter'}
        selectedItem={'Select Region'}
        handleRemove={handleRemove}
        item={multiSelectedRegion?.map((i) => i?.region)}
      >
        {regionList?.map((i, index) => (
          <div
            key={index}
            onClick={() => {
              const findSelect = multiSelectedRegion?.find(
                (data) => data?.id === i?.id
              );
              if (!findSelect) {
                handleSelectMultiData(i);
              } else {
                 toaster('warning', 'Already Selected');
              }
            }}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.region}
            </span>
          </div>
        ))}
      </MultiSelectDropDown> */}
      <MultiSelect
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        selectedItem={'Select Region'}
        handleRemove={handleRemove}
        item={multiSelectedRegion?.map((i) => i?.region)}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                const findSelect = multiSelectedRegion?.find(
                  (data) => data?.id === i?.id
                );
                if (!findSelect) {
                  handleSelectMultiData(i);
                } else {
                  toaster('warning', 'Already Selected');
                }
              }}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              // ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.region}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </MultiSelect>
    </React.Fragment>
  );
};

export default RegionFilter;
