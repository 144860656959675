import {
  DOWNLOAD_EXCEL,
  DOWNLOAD_EXCEL_SUCCESS,
  GET_AREA,
  GET_AREA_SUCCESS,
  GET_DISTRIBUTION,
  GET_DISTRIBUTION_SUCCESS,
  GET_OUTLET,
  GET_OUTLET_SUCCESS,
  GET_POINT,
  GET_POINT_SUCCESS,
  GET_REGION,
  GET_REGION_SUCCESS,
  GET_TERRITORY,
  GET_TERRITORY_SUCCESS,
  GET_TPG,
  STORE_GEO_DATA,
  STORE_NEW_DATA,
} from './actionTypes';

export const getRegionData = (token, searchValue) => ({
  type: GET_REGION,
  payload: { token, searchValue },
});
export const getTpgList = (token, searchValue) => ({
  type: GET_TPG,
  payload: { token, searchValue },
});

export const getRegionDataSuccess = (data) => ({
  type: GET_REGION_SUCCESS,
  payload: { data },
});
export const getAreaData = (
  token,
  searchValue,
  regionName,
  page,
  isNew,
  setPage,
  setHasMore
) => ({
  type: GET_AREA,
  payload: { token, searchValue, regionName, page, setPage, setHasMore, isNew },
});

export const getAreaDataSuccess = (data) => ({
  type: GET_AREA_SUCCESS,
  payload: { data },
});
export const getDistributionData = (
  token,
  searchValue,
  regionName,
  areaName,
  page,
  isNew,
  setPage,
  setHasMore
) => ({
  type: GET_DISTRIBUTION,
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
});

export const getDistributionDataSuccess = (data) => ({
  type: GET_DISTRIBUTION_SUCCESS,
  payload: { data },
});
export const getTerritoryData = (
  token,
  searchValue,
  regionName,
  areaName,
  distributionHouseName,
  page,
  isNew,
  setPage,
  setHasMore
) => ({
  type: GET_TERRITORY,
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    distributionHouseName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
});

export const getTerritoryDataSuccess = (data) => ({
  type: GET_TERRITORY_SUCCESS,
  payload: { data },
});
export const getPonitData = (
  token,
  searchValue,
  regionName,
  areaName,
  distributionHouseName,
  territoryName,
  page,
  isNew,
  setPage,
  setHasMore
) => ({
  type: GET_POINT,
  payload: {
    token,
    searchValue,
    regionName,
    areaName,
    distributionHouseName,
    territoryName,
    page,
    setPage,
    setHasMore,
    isNew,
  },
});

export const getPonitDataSuccess = (data) => ({
  type: GET_POINT_SUCCESS,
  payload: { data },
});

export const storeGeoData = (name, data) => ({
  type: STORE_GEO_DATA,
  payload: { name, data },
});

// export const removeArea = () => ({
//   type: REMOVE_AREA,
// });
export const storeNewData = (name, data) => ({
  type: STORE_NEW_DATA,
  payload: { name, data },
});
export const getOutletData = (
  token,
  regionName,
  areaName,
  distributeHouseName,
  territoryName,
  pointName,
  tpgName,
  fromScore,
  toScore,
  clusterScoreFrom,
  clusterScoreTo,

  outletScoreFrom,
  outletScoreTo,
  scopeOutlet
) => ({
  type: GET_OUTLET,
  payload: {
    token,
    regionName,
    areaName,
    distributeHouseName,
    territoryName,
    pointName,
    tpgName,
    fromScore,
    toScore,
    clusterScoreFrom,
    clusterScoreTo,
    outletScoreFrom,
    outletScoreTo,
    scopeOutlet,
  },
});

export const getOutletDataSuccess = (data) => ({
  type: GET_OUTLET_SUCCESS,
  payload: { data },
});

export const getExcelData = (
  token,
  regionName,
  areaname,
  distributeHouseName,
  territoryName,
  pointName,
  tpgName,
  fromScore,
  toScore,
  clusterScoreFrom,
  clusterScoreTo,

  outletScoreFrom,
  outletScoreTo,
  scopeOutlet
) => ({
  type: DOWNLOAD_EXCEL,
  payload: {
    token,
    regionName,
    areaname,
    distributeHouseName,
    territoryName,
    pointName,
    tpgName,
    fromScore,
    toScore,
    clusterScoreFrom,
    clusterScoreTo,
    outletScoreFrom,
    outletScoreTo,
    scopeOutlet,
  },
});

export const getExcelDataSuccess = (data) => ({
  type: DOWNLOAD_EXCEL_SUCCESS,
  payload: { data },
});
