import axios from 'axios';

export const API_URL =
  'https://maac-analytics.salesx-staging.xyz/api/v1' ||
  process.env.REACT_APP_APIKEY;

// const REACT_APP_API = "https://staging-api.field-x.org/api/v1"

export const axiosApi = axios.create({
  baseURL: API_URL,
});
//  axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('ERR', error?.response, error);
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function image(url, data, authtoken) {
  return axiosApi
    .post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config) {
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

//export postLogin

export const postLogin = async (email, password) =>
  await axios.post(
    `https://maac-analytics.salesx-staging.xyz/api/v1/user/login`,
    {
      email,
      password,
    }
  );
