export const GET_REGION = 'GET_REGION';
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';
export const GET_REGION_FAIL = 'GET_REGION_FAIL';

export const GET_AREA = 'GET_AREA';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';
export const GET_AREA_FAIL = 'GET_AREA_FAIL';

export const GET_DISTRIBUTION = 'GET_DISTRIBUTION';
export const GET_DISTRIBUTION_SUCCESS = 'GET_DISTRIBUTION_SUCCESS';
export const GET_DISTRIBUTION_FAIL = 'GET_DISTRIBUTION_FAIL';

export const GET_TERRITORY = 'GET_TERRITORY';
export const GET_TERRITORY_SUCCESS = 'GET_TERRITORY_SUCCESS';
export const GET_TERRITORY_FAIL = 'GET_TERRITORY_FAIL';

export const GET_POINT = 'GET_POINT';
export const GET_POINT_SUCCESS = 'GET_POINT_SUCCESS';
export const GET_POINT_FAIL = 'GET_POINT_FAIL';

export const GET_TPG = 'GET_TPG';
export const GET_TPG_SUCCESS = 'GET_TPG_SUCCESS';
export const GET_TPG_FAIL = 'GET_TPG_FAIL';

export const GET_OUTLET = 'GET_OUTLET';
export const GET_OUTLET_SUCCESS = 'GET_OUTLET_SUCCESS';
export const GET_OUTLET_FAIL = 'GET_OUTLET_FAIL';

export const DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL';
export const DOWNLOAD_EXCEL_SUCCESS = 'DOWNLOAD_EXCEL_SUCCESS';
export const DOWNLOAD_EXCEL_FAIL = 'DOWNLOAD_EXCEL_FAIL';

export const STORE_GEO_DATA = 'STORE_GEO_DATA';

// export const REMOVE_AREA = 'REMOVE_AREA';

export const STORE_SCORE_DATA = 'STORE_SCORE_DATA';
export const STORE_NEW_DATA = 'STORE_NEW_DATA';

export const GET_SEARCH_VALUE = 'GET_SEARCH';
export const GET_SEARCH_VALUE_SUCCESS = 'GET_SEARCH_VALUE';

export const GET_STORE_OUTLET = 'GET_STORE_OUTLET';
export const GET_STORE_OUTLET_SUCCESS = 'GET_STORE_OUTLET_SUCCESS';
