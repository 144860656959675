import React, { useRef } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import './Dropdown.scss';

const MultiSelect = ({
  toggle,
  open,
  onChange,
  searchValue,
  selectedItem,
  item,
  handleRemove,
  children,
}) => {
  const ref = useRef();
  return (
    <React.Fragment>
      <Dropdown
        isOpen={open}
        toggle={toggle}
        onClick={() => ref?.current?.focus()}
        className="mainDropDown"
      >
        <DropdownToggle
          className="bg-transparent border-0 outline-0 formbtnbtn newDropdown"
          style={{ padding: '0px', overflow: 'hidden' }}
        >
          <div className="select sizesButton d-flex justify-content-between align-items-center multiDropDownSelect">
            <span className="brandSpan">
              {item?.map((data, i) => {
                return (
                  <span
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      width: 'max-content',
                      backgroundColor: '#ddd',
                      margin: '2px',
                    }}
                    key={i}
                  >
                    <span>{data}</span>
                    <i
                      className="bx bx-x"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(i, data);
                      }}
                      style={{ color: 'red', fontSize: '20px' }}
                    ></i>
                  </span>
                );
              })}
              <input
                className="searchInput d-inline"
                style={{ width: 'max-content' }}
                type="text"
                placeholder={selectedItem}
                value={searchValue}
                onChange={onChange}
                ref={ref}
              />
            </span>
            <span
              style={{
                fontSize: '20px',
                marginTop: '3px',
                color: 'rgb(99, 99, 99)',
              }}
            >
              <i
                className="bx bxs-chevron-down"
                style={{ color: 'rgb(137 137 137)' }}
              ></i>
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="sizesDropDown"
          style={{
            height: '200px',
            overflowY: 'scroll',
            width: '100%',
            padding: '0',
            margin: '4px 0 0',
          }}
        >
          {children}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default MultiSelect;
