import {
  DOWNLOAD_EXCEL,
  DOWNLOAD_EXCEL_SUCCESS,
  GET_AREA,
  GET_AREA_SUCCESS,
  GET_DISTRIBUTION,
  GET_DISTRIBUTION_SUCCESS,
  GET_OUTLET,
  GET_OUTLET_SUCCESS,
  GET_POINT,
  GET_POINT_SUCCESS,
  GET_REGION,
  GET_REGION_SUCCESS,
  GET_SEARCH_VALUE,
  GET_SEARCH_VALUE_SUCCESS,
  GET_STORE_OUTLET,
  GET_STORE_OUTLET_SUCCESS,
  GET_TERRITORY,
  GET_TERRITORY_SUCCESS,
  GET_TPG,
  GET_TPG_SUCCESS,
  STORE_GEO_DATA,
  STORE_NEW_DATA,
} from './actionTypes';
const INIT_STATE = {
  regionList: [],
  areaList: [],
  distributionList: [],
  territoryList: [],
  pointList: [],
  score: [0, 100],
  cluster: [0, 30],
  outletScore: [0, 70],
  tpgList: [],
  multiSelectedArea: [],
  multiSelectedPoint: [],
  multiSelectedTerritory: [],
  multiSelectedDistribution: [],
  multiSelectedTpg: [],
  multiSelectedRegion: [],
  outletData: {},
  searchValue: {},
  selectedTpg: {},
  selectedArea: {},
  selectedRegion: {},
  selectedDistribution: {},
  selectedTerritory: {},
  selectedPoint: {},
  scoreOutletNum: 100,
  isSearch: false,
  regionListLoading: true,
  areaListLoading: true,
  distributionListLoading: true,
  territoryLoading: true,
  pointLoading: true,
  outletLoading: false,
};

const GeoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGION:
      return {
        ...state,
        regionListLoading: true,
      };
    case STORE_GEO_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    // case REMOVE_AREA:
    //   return {
    //     ...state,
    //     selectedArea: {},
    //     selectedRegion: {},
    //     selectedDistribution: {},
    //     selectedTerritory: {},
    //   };
    case STORE_NEW_DATA:
      return {
        ...state,
        [action.payload.name]: [
          ...new Set([...state[action.payload.name], ...action.payload.data]),
        ],
      };
    case GET_REGION_SUCCESS:
      return {
        ...state,
        regionList: action.payload,
        regionListLoading: false,
      };

    case GET_AREA:
      return {
        ...state,
        areaListLoading: true,
      };

    case GET_AREA_SUCCESS:
      return {
        ...state,
        areaList: action.payload,
        selectedArea: {},
        selectedRegion: {},
        areaListLoading: false,
      };
    case GET_DISTRIBUTION:
      return {
        ...state,
        distributionListLoading: true,
      };
    case GET_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        distributionList: action.payload,
        selectedDistribution: {},
        selectedArea: {},
        selectedRegion: {},
        distributionListLoading: false,
      };
    case GET_TERRITORY:
      return {
        ...state,
        territoryLoading: true,
      };
    case GET_TERRITORY_SUCCESS:
      return {
        ...state,
        territoryList: action.payload,
        selectedTerritory: {},
        selectedArea: {},
        selectedRegion: {},
        selectedDistribution: {},
        territoryLoading: false,
      };
    case GET_POINT:
      return {
        ...state,
        pointLoading: true,
      };
    case GET_POINT_SUCCESS:
      return {
        ...state,
        pointList: action.payload,
        selectedPoint: {},
        selectedArea: {},
        selectedRegion: {},
        selectedDistribution: {},
        selectedTerritory: {},
        pointLoading: false,
      };
    case GET_TPG:
      return {
        ...state,
      };

    case GET_TPG_SUCCESS:
      return {
        ...state,
        tpgList: action.payload,
      };

    case GET_OUTLET:
      return {
        ...state,
        outletLoading: true,
        searchValue: true,
      };

    case GET_OUTLET_SUCCESS:
      return {
        ...state,
        outletData: action.payload.data,
        outletLoading: false,
        isSearch: true,
      };
    case DOWNLOAD_EXCEL:
      return {
        ...state,
        downloadExcelLoading: true,
        searchValue: true,
      };

    case DOWNLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        downloadExcel: action.payload.data,
        downloadExcelLoading: false,
        isSearch: true,
      };
    case GET_SEARCH_VALUE:
      return {
        ...state,
      };

    case GET_SEARCH_VALUE_SUCCESS:
      return {
        ...state,
        searchValue: action.payload,
      };
    case GET_STORE_OUTLET:
      return {
        ...state,
      };

    case GET_STORE_OUTLET_SUCCESS:
      return {
        ...state,
        scoreOutletNum: action.payload,
      };

    default:
      return state;
  }
};

export default GeoReducer;
