import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../../Common/MultiSelect';
import { toaster } from '../../../helpers/custom/toaster';
import useInfiniteScroll from '../../../Hooks/useInfiniteScroll';
import { storeGeoData } from '../../../store/actions';
const TerritoryFilter = () => {
  // const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [reRender, setReRender] = useState(false);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [sizesOpen, setSizesOpen] = useState(false);

  const {
    token,
    // areaList,
    // selectedRegion,
    // selectedArea,
    // territoryList,
    // selectedDistribution,
    // selectedTerritory,
    multiSelectedTerritory,
    multiSelectedArea,
    multiSelectedRegion,
    multiSelectedDistribution,
  } = useSelector((store) => ({
    token: store.Login.token,
    territoryList: store.GeoReducer.territoryList,
    selectedTerritory: store.GeoReducer.selectedTerritory,
    selectedDistribution: store.GeoReducer.selectedDistribution,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    multiSelectedTerritory: store.GeoReducer.multiSelectedTerritory,
    multiSelectedDistribution: store.GeoReducer.multiSelectedDistribution,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
  }));

  const listArea = multiSelectedArea.map((item) => item.area);

  const listDistribution = multiSelectedDistribution.map(
    (item) => item.distributionHouse
  );
  const listRegion = multiSelectedRegion.map((item) => item.region);
  // const { value, onChange, setValue } = useDebounce(
  //   () =>
  //     dispatch(
  //       getTerritoryData(
  //         token,
  //         value,
  //         selectedRegion?.region || "",
  //         selectedArea?.area || "",
  //         selectedDistribution?.distributionHouse || "",
  //         0,
  //         true
  //       )
  //     ),
  //   1000
  // );

  // const handleSelectData = (data) => {
  //   dispatch(storeGeoData("selectedTerritory", data));
  //   setSizesOpen(!sizesOpen);
  // };

  // const fetchMoreData = () => {
  //   dispatch(
  //     getTerritoryData(
  //       token,
  //       value,
  //       listRegion || "",
  //       listArea || "",
  //       listDistribution || "",
  //       page,
  //       false,
  //       setPage,
  //       setHasMore
  //     )
  //   );
  // };

  useEffect(() => {
    setPage(0);
    setReRender(!reRender);
    // dispatch(
    //   getTerritoryData(
    //     token,
    //     value,
    //     listRegion || "",
    //     listArea || "",
    //     listDistribution || "",
    //     0,
    //     true
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRegion.length, listArea.length, listDistribution.length]);
  const handleSelectMultiData = (data) => {
    dispatch(
      storeGeoData('multiSelectedTerritory', [...multiSelectedTerritory, data])
    );
    setSizesOpen(!sizesOpen);
  };

  const handleRemove = (i, data) =>
    dispatch(
      storeGeoData(
        'multiSelectedTerritory',
        multiSelectedTerritory?.filter(
          (item, idx) => item.territory !== data.territory && idx !== i
        )
      )
    );

  let body = {
    key: query,
    limit: 10,
    page: page,
  };

  if (listArea.length > 0) {
    body.areaNames = listArea;
  }
  if (listRegion.length > 0) {
    body.regionNames = listRegion;
  }
  if (listDistribution.length > 0) {
    body.distributionHouseNames = listDistribution;
  }

  const { loading, error, data, errorMessage, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'post',
      url: `/territory`,
      body,
      token,
      name: 'territories',
      responseCallback: (data) => {
        // console.log('CallBack', data);
      },
      errorCallback: (error) => {
        // console.log('error', error);
      },
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });



  return (
    <React.Fragment>
      {/* <DropDown2
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={territoryList}
        selectedItem={selectedTerritory?.territory || 'Select Territory'}
        isSelected={Object.keys(selectedTerritory)?.length > 0}
        handleClear={() => dispatch(storeGeoData('selectedTerritory', {}))}
        hasMore={hasMore}
        scrollDiv={'territoryFilter'}
      >
        {territoryList?.map((i, index) => (
          <div
            key={index}
            onClick={() => handleSelectData(i)}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
      
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.territory}
            </span>
          </div>
        ))}
      </DropDown2> */}

      {/* <MultiSelectDropDown
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue("");
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={territoryList}
        hasMore={hasMore}
        scrollDiv={"territoryFilter"}
        selectedItem={"Select territory"}
        handleRemove={handleRemove}
        item={multiSelectedTerritory?.map((i) => i?.territory)}
      >
        {territoryList?.map((i, index) => (
          <div
            key={index}
            onClick={() => {
              const findSelect = multiSelectedTerritory?.find(
                (data) => data?.id === i?.id
              );
              if (!findSelect) {
                handleSelectMultiData(i);
              } else {
                toaster("warning", "Already Selected");
              }
            }}
            style={{ cursor: "pointer" }}
            className={"grey dropDownItem"}
          >
            <span
              style={{
                margin: "2px",
                padding: "4px",
                display: "block",
              }}
            >
              {i?.territory}
            </span>
          </div>
        ))}
      </MultiSelectDropDown> */}
      <MultiSelect
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        selectedItem={'Select Territory'}
        handleRemove={handleRemove}
        item={multiSelectedTerritory?.map((i) => i?.territory)}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                const findSelect = multiSelectedTerritory?.find(
                  (data) => data?.id === i?.id
                );
                if (!findSelect) {
                  handleSelectMultiData(i);
                } else {
                  toaster('warning', 'Already Selected');
                }
              }}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.territory}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </MultiSelect>
    </React.Fragment>
  );
};

export default TerritoryFilter;
