import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import DetailsPage from './Pages/DetailsPage/DetailsPage';
import Home from './Pages/HomePage/Home';
import Login from './Pages/Login/Login';

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/dashboard">
            <Home />
          </Route>
          <Route exact path="/details-page">
            <DetailsPage />
          </Route>
        </Switch>
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
