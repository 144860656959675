import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../../Common/MultiSelect';
import { toaster } from '../../../helpers/custom/toaster';
import useInfiniteScroll from '../../../Hooks/useInfiniteScroll';
import { storeGeoData } from '../../../store/actions';

const PointFilter = () => {
  // const [hasMore, setHasMore] = useState(true);
  const [reRender, setReRender] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [sizesOpen, setSizesOpen] = useState(false);

  const {
    token,
    // areaList,
    // selectedRegion,
    // selectedArea,
    // pointList,
    // selectedDistribution,
    // selectedTerritory,
    // selectedPoint,
    multiSelectedPoint,
    multiSelectedTerritory,
    multiSelectedDistribution,
    multiSelectedRegion,
    multiSelectedArea,
  } = useSelector((store) => ({
    token: store.Login.token,
    pointList: store.GeoReducer.pointList,
    selectedPoint: store.GeoReducer.selectedPoint,
    selectedTerritory: store.GeoReducer.selectedTerritory,
    selectedDistribution: store.GeoReducer.selectedDistribution,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    multiSelectedPoint: store.GeoReducer.multiSelectedPoint,
    multiSelectedTerritory: store.GeoReducer.multiSelectedTerritory,
    multiSelectedDistribution: store.GeoReducer.multiSelectedDistribution,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
  }));
  const listArea = multiSelectedArea.map((item) => item.area);
  const listTerritory = multiSelectedTerritory.map((item) => item.territory);
  const listDistribution = multiSelectedDistribution.map(
    (item) => item.distributionHouse
  );
  const listRegion = multiSelectedRegion.map((item) => item.region);
  // const { value, onChange, setValue } = useDebounce(
  //   () =>
  //     dispatch(
  //       getPonitData(
  //         token,
  //         value,
  //         listRegion || '',
  //         listArea || '',
  //         listDistribution || '',
  //         listTerritory || '',
  //         0,
  //         true
  //       )
  //     ),
  //   1000
  // );

  // const handleSelectData = (data) => {
  //   dispatch(storeGeoData('selectedPoint', data));
  //   setSizesOpen(!sizesOpen);
  // };

  // const fetchMoreData = () => {
  //   dispatch(
  //     getPonitData(
  //       token,
  //       value,
  //       listRegion || '',
  //       listArea || '',
  //       listDistribution || '',
  //       listTerritory || '',
  //       page,
  //       false,
  //       setPage,
  //       setHasMore
  //     )
  //   );
  // };

  useEffect(() => {
    setPage(0);
    setReRender(!reRender);
    // dispatch(
    //   getPonitData(
    //     token,
    //     value,
    //     listRegion || '',
    //     listArea || '',
    //     listDistribution || '',
    //     listTerritory || '',
    //     0,
    //     true
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listRegion.length,
    listArea.length,
    listDistribution.length,
    listTerritory.length,
  ]);

  let body = {
    key: query,
    limit: 10,
    page: page,
  };
  if (listArea.length > 0) {
    body.areaNames = listArea;
  }
  if (listRegion.length > 0) {
    body.regionNames = listRegion;
  }
  if (listDistribution.length > 0) {
    body.distributionHouseNames = listDistribution;
  }
  if (listTerritory.length > 0) {
    body.territoryNames = listTerritory;
  }

  const { loading, error, data, errorMessage, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'post',
      url: `/point`,
      body,
      token,
      name: 'points',
      responseCallback: (data) => {
        // console.log('CallBack', data);
      },
      errorCallback: (error) => {
        // console.log('error', error);
      },
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });

  // console.log(`points ~ loading`, loading, errorMessage);

  const handleSelectMultiData = (data) => {
    dispatch(storeGeoData('multiSelectedPoint', [...multiSelectedPoint, data]));
    setSizesOpen(!sizesOpen);
  };

  const handleRemove = (i, data) =>
    dispatch(
      storeGeoData(
        'multiSelectedPoint',
        multiSelectedPoint?.filter(
          (item, idx) => item.point !== data.point && idx !== i
        )
      )
    );
  return (
    <React.Fragment>
      {/* <DropDown2
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={pointList}
        selectedItem={selectedPoint?.point || 'Select Point'}
        isSelected={Object.keys(selectedPoint)?.length > 0}
        handleClear={() => dispatch(storeGeoData('selectedPoint', {}))}
        hasMore={hasMore}
        scrollDiv={'pointFilter'}
      >
        {pointList?.map((i, index) => (
          <div
            key={index}
            onClick={() => handleSelectData(i)}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
          
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.point}
            </span>
          </div>
        ))}
      </DropDown2> */}

      {/* <MultiSelectDropDown
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={pointList}
        hasMore={hasMore}
        scrollDiv={'pointFilter'}
        selectedItem={'Select Point'}
        handleRemove={handleRemove}
        item={multiSelectedPoint?.map((i) => i?.point)}
      >
        {pointList?.map((i, index) => (
          <div
            key={index}
            onClick={() => {
              const findSelect = multiSelectedPoint?.find(
                (data) => data?.id === i?.id
              );
              if (!findSelect) {
                handleSelectMultiData(i);
              } else {
                toaster('warning', 'Already Selected');
              }
            }}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.point}
            </span>
          </div>
        ))}
      </MultiSelectDropDown> */}
      <MultiSelect
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        selectedItem={'Select Point'}
        handleRemove={handleRemove}
        item={multiSelectedPoint?.map((i) => i?.point)}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                const findSelect = multiSelectedPoint?.find(
                  (data) => data?.id === i?.id
                );
                if (!findSelect) {
                  handleSelectMultiData(i);
                } else {
                  toaster('warning', 'Already Selected');
                }
              }}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.point}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </MultiSelect>
    </React.Fragment>
  );
};

export default PointFilter;
