import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletData, storeGeoData } from '../../store/Geo/action';
import Cluster from '../Cluster/Cluster';
import Outlet from '../Outlet/Outlet';
import ScorePage from '../ScorePage/ScorePage';
import AreaFilter from './Filters/AreaFilter';
import DistributionFilter from './Filters/DistributionFilter';
import NationalFilter from './Filters/NationalFilter';
import PointFilter from './Filters/PointFilter';
import RegionFilter from './Filters/RegionFilter';
import TerritoryFilter from './Filters/TerritoryFilter';
import TpgFilter from './Filters/TpgFilter';
import './geo.scss';
const GeoSearch = () => {
  const dispatch = useDispatch();
  const options = [{ value: 'Bangladesh', label: 'bangladesh' }];
  const [selectedOption, setSelectedOption] = useState(true);
  const [scoreOutlet, selectedScoreOutlet] = useState(100);

  const {
    token,
    areaList,
    selectedRegion,
    selectedArea,
    pointList,
    selectedDistribution,
    selectedTerritory,
    selectedPoint,
    cluster,
    score,
    selectedTpg,
    outletScore,
    searchValue,
    outletData,
    outletLoading,
    isSearch,
    scoreOutletNum,
    multiSelectedArea,
    multiSelectedPoint,
    multiSelectedTerritory,
    multiSelectedDistribution,
    multiSelectedTpg,
    multiSelectedRegion

  } = useSelector((store) => ({
    token: store.Login.token,
    selectedPoint: store.GeoReducer.selectedPoint,
    selectedTerritory: store.GeoReducer.selectedTerritory,
    selectedDistribution: store.GeoReducer.selectedDistribution,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    cluster: store.GeoReducer.cluster,
    score: store.GeoReducer.score,
    outletScore: store.GeoReducer.outletScore,
    selectedTpg: store.GeoReducer.selectedTpg,
    outletData: store.GeoReducer.outletData,
    outletLoading: store.GeoReducer.outletLoading,
    isSearch: store.GeoReducer.isSearch,
    scoreOutletNum: store.GeoReducer.scoreOutletNum,
    multiSelectedPoint: store.GeoReducer.multiSelectedPoint,
    multiSelectedTerritory: store.GeoReducer.multiSelectedTerritory,
    multiSelectedDistribution: store.GeoReducer.multiSelectedDistribution,
    multiSelectedTpg: store.GeoReducer.multiSelectedTpg,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
  }));

  let clusterTo = Math.ceil(cluster?.[1]);
  let clusterFrom = Math.ceil(cluster?.[0]);
  let scoreTo = Math.ceil(score?.[1]);
  let scoreFrom = Math.ceil(score?.[0]);
  let outletScoreTo = Math.ceil(outletScore?.[1]);
  let outletScoreFrom = Math.ceil(outletScore?.[0]);


  const handleSearch = () => {
    const listPoint = multiSelectedPoint.map((item) => item.point);
    const listArea = multiSelectedArea.map((item) => item.area);
    const listTerritory = multiSelectedTerritory.map((item) => item.territory);
    const listDistribution = multiSelectedDistribution.map((item) => item.distributionHouse);
    const listTpg = multiSelectedTpg.map((item) => item.tpgName);
    const listRegion = multiSelectedRegion.map((item) => item.region);
    
    
    console.log(`listRegion`, listRegion);
    // dispatch(storeGeoData("searchValue", selectedOption));
    dispatch(
      getOutletData(
        token,
        listRegion ,
        listArea,
        listDistribution ,
        listTerritory ,
        listPoint ,
        listTpg ,
        scoreFrom || 0,
        scoreTo || 100,
        clusterFrom || 0,
        clusterTo || 30,
        outletScoreFrom || 0,
        outletScoreTo || 70,
        scoreOutletNum || 100
      )
    );
  };
  // const handleOutlet = (e) =>{
  //   selectedScoreOutlet(e.target.value)

  //   dispatch(storeGeoData("scoreOutletNum", scoreOutlet ))

  // }
  return (
    <div>
      <Container>
        <div className="geo-page-inner-wrap">
          <div className="geo-page-title">
            <h3>GEO</h3>
          </div>
          <Row>
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3 input-arrow-hide-button-class"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>National</Form.Label>
                  <NationalFilter />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Region</Form.Label>
                  <RegionFilter />
                  {/* <Select
                    aria-label="All"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Area</Form.Label>
                  <AreaFilter />
                  {/* <Select
                    aria-label="All"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Distribution</Form.Label>
                  <DistributionFilter />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Territory</Form.Label>
                  <TerritoryFilter />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Point</Form.Label>
                  <PointFilter />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>TPG</Form.Label>
                  <TpgFilter />
                </Form.Group>
              </div>
            </Col>{' '}
            {/* <Col lg={4}>
             
            </Col>{" "} */}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group controlId="exampleForm.ControlInput1" className="">
                  <Form.Label className="mb-2">Scope Outlet ( Default: 100 )</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Scope Outlet"
                    className="scoreOutletNumber"
                    onChange={(e) => {
                      selectedScoreOutlet(e.target.value);
                      dispatch(storeGeoData('scoreOutletNum', e.target.value));
                    }}
                  />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              {/* <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter number of outlet</Form.Label>
                  <PointFilter />
                </Form.Group>
               </div> */}
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Cluster />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Outlet />
                </Form.Group>
              </div>
            </Col>{' '}
            <Col lg={4}>
              <div className="geopage-single-item ">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <ScorePage />
                </Form.Group>
              </div>
            </Col>{' '}
          </Row>

          <div className="search-main-button-wrap ">
            <button disabled={outletLoading} onClick={handleSearch}>
              {outletLoading ? 'Searching...' : 'Search'}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GeoSearch;
