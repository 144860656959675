import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../../Common/MultiSelect';
import { toaster } from '../../../helpers/custom/toaster';
import useInfiniteScroll from '../../../Hooks/useInfiniteScroll';
import { storeGeoData } from '../../../store/actions';
const DistributionFilter = () => {
  // const [hasMore, setHasMore] = useState(true);
  const [reRender, setReRender] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [sizesOpen, setSizesOpen] = useState(false);

  const {
    token,
    // areaList,
    // selectedRegion,
    // selectedArea,
    // distributionList,
    // selectedDistribution,
    multiSelectedDistribution,
    multiSelectedArea,
    multiSelectedRegion,
  } = useSelector((store) => ({
    token: store.Login.token,
    distributionList: store.GeoReducer.distributionList,
    selectedDistribution: store.GeoReducer.selectedDistribution,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    multiSelectedDistribution: store.GeoReducer.multiSelectedDistribution,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
  }));
  const listArea = multiSelectedArea.map((item) => item.area).join(',');
  const listRegion = multiSelectedRegion.map((item) => item.region).join(',');
  // const { value, onChange, setValue } = useDebounce(
  //   () =>
  //     dispatch(
  //       getDistributionData(
  //         token,
  //         value,
  //         listRegion || '',
  //         listArea || '',
  //         0,
  //         true
  //       )
  //     ),
  //   1000
  // );

  // const handleSelectData = (data) => {
  //   dispatch(storeGeoData('selectedDistribution', data));
  //   setSizesOpen(!sizesOpen);
  // };

  // const fetchMoreData = () => {
  //   // const nextPage = page + 1;
  //   dispatch(
  //     getDistributionData(
  //       token,
  //       value,
  //       listRegion || '',
  //       listArea || '',
  //       page,
  //       false,
  //       setPage,
  //       setHasMore
  //     )
  //   );
  // };

  useEffect(() => {
    setPage(0);
    setReRender(!reRender);
    // dispatch(
    //   getDistributionData(
    //     token,
    //     value,
    //     listRegion || '',
    //     listArea || '',
    //     0,
    //     true
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRegion, listArea]);
  const handleSelectMultiData = (data) => {
    dispatch(
      storeGeoData('multiSelectedDistribution', [
        ...multiSelectedDistribution,
        data,
      ])
    );
    setSizesOpen(!sizesOpen);
  };

  const { loading, error, data, errorMessage, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'Get',
      url: `/distribution-house?key=${query}${
        listRegion ? `&regionNames= ${listRegion}` : ''
      }${listArea ? `&areaNames= ${listArea}` : ''}&page=${page}&limit=10`,
      body: {},
      token,
      name: 'distributionHouses',
      responseCallback: (data) => {
        // console.log('CallBack', data);
      },
      errorCallback: (error) => {
        // console.log('error', error);
      },
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });


  const handleRemove = (i, data) =>
    dispatch(
      storeGeoData(
        'multiSelectedDistribution',
        multiSelectedDistribution?.filter(
          (item, idx) =>
            item.distributionHouse !== data.distributionHouse && idx !== i
        )
      )
    );
  return (
    <React.Fragment>
      {/* <DropDown2
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={distributionList}
        isSelected={Object.keys(selectedDistribution)?.length > 0}
        handleClear={() => dispatch(storeGeoData('selectedDistribution', {}))}
        selectedItem={
          selectedDistribution?.distributionHouse || 'Select Distribution House'
        }
        hasMore={hasMore}
        scrollDiv={'distributionFilter'}
      >
        {distributionList?.map((i, index) => (
          <div
            key={index}
            onClick={() => handleSelectData(i)}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
            
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.distributionHouse}
            </span>
          </div>
        ))}
      </DropDown2> */}
      {/* <MultiSelectDropDown
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={distributionList}
        hasMore={hasMore}
        scrollDiv={'distributionFilter'}
        selectedItem={'Select Distribution'}
        handleRemove={handleRemove}
        item={multiSelectedDistribution?.map((i) => i?.distributionHouse)}
      >
        {distributionList?.map((i, index) => (
          <div
            key={index}
            onClick={() => {
              const findSelect = multiSelectedDistribution?.find(
                (data) => data?.id === i?.id
              );
              if (!findSelect) {
                handleSelectMultiData(i);
              } else {
                toaster('warning', 'Already Selected');
              }
            }}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >
            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.distributionHouse}
            </span>
          </div>
        ))}
      </MultiSelectDropDown> */}
      <MultiSelect
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        selectedItem={'Select Distribution'}
        handleRemove={handleRemove}
        item={multiSelectedDistribution?.map((i) => i?.distributionHouse)}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                const findSelect = multiSelectedDistribution?.find(
                  (data) => data?.id === i?.id
                );
                if (!findSelect) {
                  handleSelectMultiData(i);
                } else {
                  toaster('warning', 'Already Selected');
                }
              }}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.distributionHouse}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </MultiSelect>
    </React.Fragment>
  );
};

export default DistributionFilter;
