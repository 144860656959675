import React from "react";
import Footer from "../../Common/Footer/Footer";
import Header from "../../Common/Header/Header";
import GeoSearch from "../../Components/GEO/GeoSearch";
import DetailsPage from "../DetailsPage/DetailsPage";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const {

    searchValue
  } = useSelector((store) => ({
    token: store.Login.token,
    searchValue: store.GeoReducer.searchValue,
  
  }));



  return (
    <div>
      <Header />
      <GeoSearch />
      {
        searchValue === true ?
        <DetailsPage /> : <span></span>

      }
     
      <Footer />
    </div>
  );
};

export default Home;
