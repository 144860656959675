import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from '../../../Common/MultiSelect';
import { toaster } from '../../../helpers/custom/toaster';
import useInfiniteScroll from '../../../Hooks/useInfiniteScroll';
import { storeGeoData } from '../../../store/actions';

const AreaFilter = () => {
  const [reRender, setReRender] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [sizesOpen, setSizesOpen] = useState(false);

  // console.log(`AreaFilter ~ page`, page);
  const {
    token,
    // areaList,
    // selectedRegion,
    // selectedArea,
    multiSelectedArea,
    multiSelectedRegion,
  } = useSelector((store) => ({
    token: store.Login.token,
    areaList: store.GeoReducer.areaList,
    selectedRegion: store.GeoReducer.selectedRegion,
    selectedArea: store.GeoReducer.selectedArea,
    multiSelectedArea: store.GeoReducer.multiSelectedArea,
    multiSelectedRegion: store.GeoReducer.multiSelectedRegion,
  }));
  // console.log('areaList', areaList);
  const listRegion = multiSelectedRegion.map((item) => item.region).join(',');

  // const { value, onChange, setValue } = useDebounce(() => {
  //   console.log('call');
  //   dispatch(getAreaData(token, value, listRegion || '', 0, true));
  // }, 1000);

  // const handleSelectData = (data) => {
  //   dispatch(storeGeoData('selectedArea', data));
  //   setSizesOpen(!sizesOpen);
  // };

  const handleSelectMultiData = (data) => {
    dispatch(storeGeoData('multiSelectedArea', [...multiSelectedArea, data]));
    setSizesOpen(!sizesOpen);
  };

  const handleRemove = (i, data) =>
    dispatch(
      storeGeoData(
        'multiSelectedArea',
        multiSelectedArea?.filter(
          (item, idx) => item.area !== data.area && idx !== i
        )
      )
    );

  // const fetchMoreData = () => {
  //   console.log('load more');
  //   // const nextPage = page + 1;
  //   dispatch(
  //     getAreaData(
  //       token,
  //       value,
  //       listRegion || '',
  //       page,
  //       false,
  //       setPage
  //       // setHasMore
  //     )
  //   );
  // };
  
  useEffect(() => {
    setPage(0);
    setReRender(!reRender);
    // dispatch(getAreaData(token, value, listRegion || '', 0, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRegion]);
  const [query, setQuery] = useState('');

  const { loading, error, data, errorMessage, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'Get',
      url: `/area?key=${query}${
        listRegion ? `&regionNames= ${listRegion}` : ''
      }&page=${page}&limit=10`,
      body: {},
      token,
      name: 'areas',
      responseCallback: (data) => {
        // console.log('CallBack', data);
      },
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });


  return (
    <React.Fragment>
      {/* <DropDown2
        fetchMoreData={fetchMoreData}
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setValue('');
        }}
        open={sizesOpen}
        onChange={onChange}
        searchValue={value}
        list={areaList}
        isSelected={Object.keys(selectedArea)?.length > 0}
        handleClear={() => dispatch(storeGeoData('selectedArea', {}))}
        selectedItem={selectedArea?.area || 'Select Area'}
        hasMore={hasMore}
        scrollDiv={'areaFilter'}
      >
        {areaList?.map((i, index) => (
          <div
            key={index}
            onClick={() => handleSelectData(i)}
            style={{ cursor: 'pointer' }}
            className={'grey dropDownItem'}
          >

            <span
              style={{
                margin: '2px',
                padding: '4px',
                display: 'block',
              }}
            >
              {i?.area}
            </span>
          </div>
        ))}
      </DropDown2> */}

      <MultiSelect
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        selectedItem={'Select Area'}
        handleRemove={handleRemove}
        item={multiSelectedArea?.map((i) => i?.area)}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                const findSelect = multiSelectedArea?.find(
                  (data) => data?.id === i?.id
                );
                if (!findSelect) {
                  handleSelectMultiData(i);
                } else {
                  toaster('warning', 'Already Selected');
                }
              }}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.area}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </MultiSelect>
    </React.Fragment>
  );
};

export default AreaFilter;
