import { combineReducers } from 'redux';
import GeoReducer from './Geo/reducers';
import Login from './Login/reducer';

const rootReducer = combineReducers({
  Login,
  GeoReducer,
});

export default rootReducer;
