import {
    LOGIN_SUCCESS, LOGIN_USER, LOGIN_USER_ERROR, LOGOUT_USER,
    LOGOUT_USER_SUCCESS
  } from "./actionTypes"
  
  const initialState = {
    loading: false,
    name: "",
    token: "",
    email: "", 
    error: "",
  }
  
  const login = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER:
        state = {
          ...state,
          loading: true,
          error: "",
        }
        break
      case LOGIN_SUCCESS:
        state = {
          ...state,
          name: action.payload.name,
          token: action.payload.token,
          email: action.payload.email,
        
          loading: false,
          error: "",
        }
        break
      case LOGIN_USER_ERROR:
        state = {
          ...state,
          name: "",
          token: "",
          email: "",
         
          loading: false,
        }
        break
      case LOGOUT_USER:
        state = {
          ...state,
          name: "",
          token: "",
          email: "",
        
        }
        break
      case LOGOUT_USER_SUCCESS:
        state = { ...state }
        break
  
      default:
        state = state
        break
    }
    return state
  }
  
  export default login
  